import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RestartLoginFormService {
  private restarted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get restarted(): Observable<boolean> {
    return this.restarted$;
  }

  public restartOn() {
    this.restarted$.next(true);
  }

  public restartOff() {
    this.restarted$.next(false);
  }
}
