import { buildChecksum } from '@utils/utils';
import { Expose } from 'class-transformer';
import { RegisterPlusActionTokenDataEntity } from './action-token-data.entity';

export class RegisterOfflinePlusEntity extends RegisterPlusActionTokenDataEntity {
  @Expose()
  public checkSum: string;

  private lastCheckSum: string;
  private lastEncryptedDesktopKey: string;
  private lastEncryptedReference: string;

  private get currentCheckSum(): string {
    if (
      this.encryptedDesktopKey !== this.lastEncryptedDesktopKey ||
      this.encryptedReference !== this.lastEncryptedReference
    ) {
      this.lastCheckSum = buildChecksum(this.reference + this.desktopKey);
      this.lastEncryptedDesktopKey = this.encryptedDesktopKey;
      this.lastEncryptedReference = this.encryptedReference;
    }

    return this.lastCheckSum;
  }

  matchesCheckSum(outerCheckSum: string): boolean {
    // console.log('currentCheckSum', this.currentCheckSum); // DEBUG
    return outerCheckSum === this.currentCheckSum;
  }
}
