import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { registerRoutes } from './register.routes';
import { SharedModule } from '@shared/shared.module';
import { SelectCountryModule } from '../../shared/components/select-country/select-country.module';
import { CountryCodeSelectorModule } from '../../shared/components/country-code-selector/country-code-selector.module';
import { RouterModule } from '@angular/router';
import { RegisterActionTokenComponent } from './pages/action-token/register-action-token.component';
import { RegisterOfflinePlusComponent } from './pages/offline-plus/register-offline-plus.component';
import { RegisterFormComponent } from './components/form/register-form.component';
import { DialogsModule } from '@shared/components/dialogs/dialogs.module';
import { RegisterQRCodeComponent } from './pages/qr-code/register-qr-code.component';
import { LoginFormModule } from '@shared/components/login-form/login-form.module';

@NgModule({
  declarations: [
    RegisterOfflinePlusComponent,
    RegisterActionTokenComponent,
    RegisterFormComponent,
    RegisterQRCodeComponent,
  ],
  imports: [
    RouterModule.forChild(registerRoutes),
    CommonModule,
    SharedModule,
    CoreModule,
    DialogsModule,
    AngularMaterialModule,
    SelectCountryModule,
    CountryCodeSelectorModule,
    LoginFormModule,
  ],
  exports: [],
})
export class RegisterModule {}
