import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ShowFullRegisterFormService } from '@modules/register/show-full-register-form.service';
import { BaseComponent } from '@shared/components/base.component';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent {
  constructor(
    public localStorageService: LocalStorageService,
    private router: Router,
    private translationMWService: TranslationMWService,
    private showFullRegisterFormService: ShowFullRegisterFormService
  ) {
    super(localStorageService);

    this.showFullRegisterFormService.hideForm();
  }

  authLoginResponse(response: AuthLoginResponse) {
    this.localStorageService.feedFromLoginResponse(response);
    // set user's language preference
    this.translationMWService.setTransLanguage(response.language);
    // move to role's specific main page
    void this.router.navigate([this.localStorageService.userRolesMainPage]);
  }
}
