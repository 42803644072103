import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './login-form.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}
