<div class="container d-flex flex-column justify-content-start">
  <div class="auth-title-container">
    <div class="auth-title head-line-3">{{ 'auth.login.title' | translate }}</div>
    <div class="auth-subtitle m3-title-small">{{ 'auth.login.subtitle' | translate }}</div>
  </div>
  <div><app-login-form (authLoginResponse)="authLoginResponse($event)"></app-login-form></div>
  <div class="buttons">
    <div class="or-separator m3-label-large">
      {{ 'auth.login.orSeparator' | translate }}
    </div>
    <button
      type="button"
      mat-stroked-button
      color="primary"
      class="button-outline font-button button-register"
      [routerLink]="['/auth/register-split/offline']"
    >
      <span class="btn-text">{{ 'auth.login.registerInstallation' | translate }}</span>
    </button>
  </div>
  <div class="helpers d-flex flex-column justify-content-start align-items-center">
    <div>
      <span class="auth-subtitle m3-title-small">{{ 'login.copyright' | translate }}</span>
    </div>
    <a
      class="helper-link m3-title-small cursor-pointer"
      target="_blank"
      [routerLink]="'/privacy-policy/' + currentLanguage"
    >
      {{ 'login.privacy-policy-link' | translate }}
    </a>
  </div>
</div>
