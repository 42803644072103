<div mat-dialog-title class="header d-flex justify-content-between">
  <div class="font-headline-4">
    {{ 'login-dialog.title' | translate }}
  </div>
  <div
    class="cursor-pointer d-flex flex-column justify-content-between align-items-center close-container"
  >
    <button mat-icon-button class="list-icon-button" [mat-dialog-close]="false">
      <span class="material-symbols-outlined close-icon"> close </span>
    </button>
    <span class="font-caption color-text-black">{{ 'buttons.close' | translate }}</span>
  </div>
</div>
<div mat-dialog-content class="content">
  <form [formGroup]="loginForm" #f="ngForm">
    <div class="inputs">
      <div class="mat-form-field-container">
        <span class="fake-label m3-label-large">{{ 'login.form.email' | translate }}</span>
        <mat-form-field class="input-full-width">
          <mat-label>{{ 'auth.login.emailPlaceholder' | translate }}</mat-label>
          <input
            matInput
            formControlName="email"
            trim="blur"
            type="text"
            placeholder="{{ 'auth.login.emailPlaceholder' | translate }}"
            appToLowercase
          />
          <mat-error *ngIf="loginForm.controls.email.hasError('required')">
            {{ 'form.error.fieldRequired' | translate }} | {{ loginForm.controls.email.value }}
          </mat-error>
          <mat-error
            *ngIf="
              loginForm.controls.email.hasError('email') ||
              loginForm.controls.email.hasError('pattern')
            "
          >
            {{ 'form.error.typeEmail' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="mat-form-field-container">
        <span class="fake-label m3-label-large">{{ 'login.form.password' | translate }}</span>
        <mat-form-field class="input-full-width">
          <mat-label>{{ 'login.form.placeholders.password' | translate }}</mat-label>
          <input
            matInput
            formControlName="password"
            name="password"
            autocomplete="on"
            [type]="hidePassword ? 'password' : 'text'"
            placeholder="{{ 'login.form.placeholders.password' | translate }}"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); hidePassword = !hidePassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword"
          >
            <span class="material-symbols-outlined">
              {{ hidePassword ? 'visibility_off' : 'visibility' }}
            </span>
          </button>
          <mat-error *ngIf="loginForm.controls.password.hasError('required')">
            {{ 'form.error.fieldRequired' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="buttons-list d-flex justify-content-between">
  <button
    mat-stroked-button
    class="detail-button"
    color="secondary"
    type="button"
    [mat-dialog-close]="false"
  >
    <span class="material-symbols-outlined"> close </span>
    <span class="font-button">{{ 'buttons.cancel' | translate }}</span>
  </button>
  <button
    mat-stroked-button
    class="detail-button"
    color="primary"
    type="button"
    [disabled]="loginForm.pristine || !loginForm.valid"
    (click)="tryLogin()"
  >
    <span class="material-symbols-outlined icon"> login </span>
    <span class="btn-text">{{ 'auth.button.login' | translate }}</span>
  </button>
</div>
