import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { BEAuthService } from '@services/backend-services/auth/be-auth.service';
import { ILoginContext } from '@interfaces/login-context.interface';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { errorLoginUser } from '@utils/users';
import { BaseComponent } from '@shared/components/base.component';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';
import { RestartLoginFormService } from './restart-login-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends BaseComponent implements OnInit, OnChanges {
  public loginError = false;
  public hidePassword = true;
  public loading = false;
  public progressValue = 6;

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      Validators.email,
    ]),
    password: new FormControl('', [Validators.required]),
  });

  private subscriptions: Subscription[];

  @Input() knownEmail: string;

  @Output() authLoginResponse: EventEmitter<AuthLoginResponse> =
    new EventEmitter<AuthLoginResponse>();

  constructor(
    public localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private translationMWService: TranslationMWService,
    private beAuthService: BEAuthService,
    private restartLoginFormService: RestartLoginFormService
  ) {
    super(localStorageService);
  }

  ngOnInit(): void {
    this.subscriptions = [
      this.restartLoginFormService.restarted.subscribe((e) => {
        if (e === true) {
          this.restartFormState();
          this.restartLoginFormService.restartOff();
        }
      }),
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.knownEmail && changes.knownEmail.currentValue) {
      this.loginForm.controls.email.setValue(this.knownEmail);
    }
  }

  restartFormState() {
    this.loading = false;
    this.progressValue = 0;
  }

  loginUser() {
    if (this.loginForm.valid) {
      //Show the loading bar
      this.loading = true;
      this.progressValue = 45;

      this.beAuthService
        .login(this.loginForm.value as ILoginContext)
        .pipe(first())
        .subscribe(
          (response) => {
            //Fill the progress bar
            this.progressValue = 100;
            // timeOut to give time to see the loading bar progress
            setTimeout(() => {
              this.authLoginResponse.emit(response);
            }, 300);
          },
          (error) => {
            errorLoginUser(error, this.toastr, this.translationMWService);

            this.loginError = true;
            this.loginForm.controls.password.setValue(null);
            this.loginForm.controls.password.setErrors({ [error.error]: true });
            this.restartFormState();
          }
        );
    }
  }
}
